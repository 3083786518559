<template>
  <section id="usuarios">
    <!-- <div id="loading-bg" v-if="loading">
      <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div> -->

    <b-col cols="14">
      <b-card>
        <b-card-header class="px-0">
          <div class="container p-0">
            <div class="row align-items-center">
              <div class="col-md col-12">
                <b-card-title> Usuarios </b-card-title>
              </div>
              <div class="col-md col-12 justify-content-end">
                <div style="display: flex">
                  <div style="margin-left: auto">
                    <router-link to="/dashboard/usuarios/UsuarioNuevo">
                      <b-button variant="primary" class="shadow-none"
                        >Crear Usuario</b-button
                      >
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-card-header>

        <!-- Tabla -->
        <b-card v-if="tableData" no-body class="card-company-table">
          <b-table
            id="my-table"
            :per-page="porPagina"
            :current-page="paginaActual"
            responsive
            :items="getUser2($store.state.appConfig.projecto.name)"
            :fields="fields"
            class="mb-0 text-center"
            show-empty
            empty-text="No hay usuarios para este proyecto"
          >
            <!-- user -->
            <template #cell(user)="data">
              <div class="align-items-center">
                <div>
                  <div
                    class="font-weight-bolder text-uppercase data-items-size"
                  >
                    {{ data.item.name }}
                  </div>
                </div>
              </div>
            </template>

            <!-- rol -->
            <template #cell(rol)="data">
              <div class="align-items-center data-items-size">
                <span>{{ data.item.role }}</span>
              </div>
            </template>

            <!-- email -->
            <template #cell(email)="data">
              <div class="d-flex flex-column data-items-size">
                <span>{{ data.item.email }}</span>
              </div>
            </template>

            <!-- producto -->
            <template #cell(productos)="data">
              <div>
                <b-badge
                  class="pill-style font-weight-normal"
                  v-for="producto in data.item.productos"
                  :key="producto"
                  pill
                  variant="info"
                  >{{ producto }}</b-badge
                >
              </div>
            </template>

            <!-- accion -->
            <template #cell(accion)="data">
              <div class="align-items-center d-flex flex-row">
                <button
                  v-b-tooltip.hover
                  title="Editar"
                  class="action-button pr-2"
                  @click="navigateEditUser(data.item)"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    size="15"
                    class="text-body align-middle"
                  />
                </button>

                <button
                  class="action-button pr-2"
                  @click="showModal(data.item.id)"
                  v-b-modal.modal-eliminar
                  v-b-tooltip.hover
                  title="Eliminar usuario"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    size="15"
                    class="text-body align-middle"
                  />
                </button>
              </div>
            </template>
          </b-table>
          <b-row class="paginador" v-if="rowsLength > 0">
            <b-col cols="12" md="6" lg="6" class="mx-0">
              <p>Cantidad: {{ rowsLength }}</p>
            </b-col>
            <b-col cols="12" md="6" lg="6" class="mx-0 overflow-auto">
              <b-pagination
                v-model="paginaActual"
                :total-rows="rowsLength"
                :per-page="porPagina"
                aria-controls="my-table"
                first-text="Primera"
                prev-text="Anterior"
                next-text="Siguiente"
                last-text="Ultima"
              ></b-pagination>
            </b-col>
          </b-row>
        </b-card>
        <!--Tabla-->
      </b-card>
    </b-col>

    <!-- Modal Eliminar usuario -->
    <b-modal
      id="modal-eliminar"
      body-bg-variant="light-warning"
      v-model="modalEliminarShow"
      hide-footer
      hide-header
      centered
      size="sm"
      ref="modal-eliminar"
    >
      <label id="label-confirmacion-cancelar">
        ¿ Seguro que quieres eliminar este usuario?
      </label>
      <b-row>
        <b-col>
          <b-button
            variant="warning"
            class="mr-1 modal-button-size shadow-none"
            @click="hideEliminarModal"
          >
            No
          </b-button>

          <b-button
            variant="outline-warning"
            class="modal-button-size shadow-none"
            id="agregar-button-modal"
            @click="
              () => {
                handleRemove();
              }
            "
          >
            Sí
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BCardSubTitle,
  BCardTitle,
  BPagination,
  BTable,
  BAvatar,
  BImg,
  BButton,
  BBadge,
} from 'bootstrap-vue';

import { getUserData } from '@/auth/utils';
import Button from '@/views/components/button/Button.vue';
import axios from 'axios';
import { backendUrl } from '@/config';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    BButton,
    BPagination,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    Button,
    ToastificationContent,
  },
  data() {
    return {
      modalEliminarShow: false,
      idRemove: '',
      tableData: [],
      fields: [
        { key: 'user', label: 'USUARIO' },
        { key: 'rol', label: 'ROL' },
        { key: 'email', label: 'EMAIL' },
        { key: 'productos', label: 'PRODUCTOS', width: '10%' },
        { key: 'accion', label: 'ACCION' },
      ],
      porPagina: 5,
      paginaActual: 1,
      rowsLength: null,
    };
  },
  async created() {
 
    const token = localStorage.getItem('token');
    const userStorage = getUserData();
    const response = await axios({
      method: "get",
      url: `${backendUrl}/usuarios/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const user = response.data.user;

    // const user = this.$store.state.appConfig.usuarios;

    const products = await axios({
      method: 'get',
      url: `${backendUrl}/productos/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const productDb = products.data.project;
    for (let i = 0; i < user.length; i++) {
      user[i].productos = [];
      for (let j = 0; j < user[i].project.length; j++) {
        const [nameProject] = await productDb.filter(
          (e) => e.id === user[i].project[j].id_project
        );
        user[i].productos.push(nameProject.name);
      }
    }

    this.tableData = user;
  },

  async mounted() {
    this.$store.commit('appConfig/changeLoading', true);
    if (this.$store.state.appConfig.rol != 3) {
      this.$store.commit('appConfig/changeLoading', false);
      this.$router.push({ path: '/' });
    } else {
      
    }
  },
  methods: {
    showEliminarUsuarioError(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Hubo un problema al eliminar el usuario',
          text: 'Intente Nuevamente',
          icon: 'XIcon',
          variant,
        },
      });
    },
    showEliminarUsuarioSuccess(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: '¡Se ha eliminado el usuario exitosamente!',
          icon: 'CheckIcon',
          variant,
        },
      });
    },
    showModal(id) {
      this.idRemove = id;
      modalEliminarShow = !modalEliminarShow;
    },
    hideEliminarModal() {
      this.$refs['modal-eliminar'].hide();
    },

    getUser2(nameProduct) {
      let usuariosDeProjecto = [];

      for (let k = 0; k < this.tableData.length; k++) {
        for (let l = 0; l < this.tableData[k].productos.length; l++) {
          if (this.tableData[k].productos[l] === nameProduct) {
            usuariosDeProjecto.push(this.tableData[k]);
          }
        }
      }

      if (usuariosDeProjecto.length > 0) {
        this.$store.commit('appConfig/changeLoading', false);
      }

      this.rowsLength = usuariosDeProjecto.length;
      return usuariosDeProjecto;
    },
    navigateEditUser(item) {
      this.$router.push(
        `/dashboard/usuarios/UsuarioNuevo/?userId=${item.id}&name=${item.name}&project=${item.productos}&rol=${item.role}&email=${item.email}`
      );
    },

    async getUsers() {
      const token = localStorage.getItem('token');
      const userStorage = getUserData();

      if (userStorage.role !== 'ADMIN') {
        alert('No tiene permisos para ver los usuarios');
        return;
      }
      /*
      // data
      const response = await axios({
        method: "get",
        url: `${backendUrl}/usuarios/`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const user = response.data.user; */
      const user = this.$store.state.appConfig.usuarios;

      /* const products = await axios({
        method: "get",
        url: `${backendUrl}/productos/`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }); */

      const productDb = this.$store.state.appConfig.proyectosTotales;
      for (let i = 0; i < user.length; i++) {
        user[i].productos = [];
        for (let j = 0; j < user[i].project.length; j++) {
          const [nameProject] = await productDb.filter(
            (e) => e.id === user[i].project[j].id_project
          );
          user[i].productos.push(nameProject.name);
        }
      }

      this.tableData = user;

      if (this.tableData.length > 0) {
        // this.loading = false;
        this.$store.commit('appConfig/changeLoading', false);
      }
    },
    async handleRemove() {
      this.$store.commit('appConfig/changeLoading', true);

      const token = localStorage.getItem('token');

      var data = JSON.stringify({
        id: this.idRemove,
      });

      var config = {
        method: 'post',
        url: `${backendUrl}/usuarios/rm`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: data,
      };
      try {
        this.$store.commit('appConfig/changeLoading', false);
        this.showEliminarUsuarioSuccess('success');

        await axios(config);

        const newTable = await this.tableData.filter(
          (e) => e.id !== this.idRemove
        );

        const response = await axios({
          method: 'get',
          url: `${backendUrl}/usuarios`,
          headers: {
            'Content-Type': 'application/json',
          },
        });

        this.$store.commit('appConfig/saveUsuarios', response.data.user);

        this.tableData = newTable;

        this.hideEliminarModal();
        return;
      } catch (error) {
        this.$store.commit('appConfig/changeLoading', true);
        this.showEliminarUsuarioError('danger');
        return error;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}

.pill-style {
  margin-right: 4px;
  margin-bottom: 2px;
}

.data-items-size {
  font-size: 12px;
}

::v-deep .table th {
  text-align: center !important;
}

.action-button {
  border: none;
  background: transparent;
  width: 20px;
}

#label-confirmacion-cancelar {
  margin-left: 11%;
  margin-bottom: 16px;
  color: $warning !important;
  font-weight: bold;
  font-size: 14px;
}

.modal-button-size {
  width: 173px;
}

//SPINNER
#loading-logo {
  width: 50%;
}
#loading-bg {
  width: 100%;
  height: 100%;
  background: #fff;
  display: block;
  position: absolute;
}
.loading-logo {
  position: absolute;
  left: calc(46% - 45px);
  top: 40%;
}
.loading {
  position: absolute;
  left: calc(50% - 35px);
  top: 50%;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
}
.loading .effect-1,
.loading .effect-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid rgba(0, 26, 87, 1);
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effect-1 {
  animation: rotate 1s ease infinite;
}
.loading .effect-2 {
  animation: rotateOpacity 1s ease infinite 0.1s;
}
.loading .effect-3 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid rgba(0, 26, 87, 1);
  -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
  animation: rotateOpacity 1s ease infinite 0.2s;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effects {
  transition: all 0.3s ease;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}

.paginador {
  margin-top: 20px;
}
</style>
